import { Link } from 'gatsby';
import React, { useState, useEffect } from 'react';
import Icon from '../../icon';
import Nav from '../../nav';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import styles from './header.module.scss';
import theme from '../../../styles/theme.module.scss';

const Header = ({ visible }) => {
  const [showNav, setShowNav] = useState(false);
  const showClass = showNav ? 'toggled' : '';
  function handleShowNav() {
    setShowNav(true);
  }
  function handleHideNav() {
    setShowNav(false);
  }
  const breakpoints = useBreakpoint();

  return (
    <header
      className={`${styles.root} ${visible ? styles.dark : styles.light} ${
        showNav ? styles.dark : ''
      }`}
      style={{ paddingLeft: 'calc(100vw - 100%)' }}>
      <div className={styles.wrapper}>
        <div className={[styles.toggleNavButton, styles[showClass]].join(' ')}>
          <button
            onClick={showNav ? handleHideNav : handleShowNav}
            aria-label='Main menu'
            aria-expanded={showNav ? 'true' : 'false'}
            aria-controls='menu'
            tabIndex='0'>
            {showNav ? <Icon symbol='close' /> : <Icon symbol='hamburger' />}
          </button>
        </div>

        <div className={styles.branding}>
          <Link to='/'>
            {!breakpoints.md && (
              <>
                <Icon symbol='logo' />
              </>
            )}
            {breakpoints.md && (
              <>
                <Icon symbol='logomobile' />
              </>
            )}
          </Link>
        </div>

        <div className={`${styles.contact} ${showNav ? theme.isHidden : theme.isShown}`}>
          <Link
            className={`${theme.h5_founders} ${styles.contactLink} ${theme.text_link}`}
            to='/contact'>
            Contact
          </Link>
        </div>
      </div>

      <Nav showNav={showNav} onHideNav={handleHideNav} onShowNav={handleShowNav} />
    </header>
  );
};

export default Header;
