import React from 'react';
import Link from '../../link';
import NewsletterForm from './newsletter-form';
import Logo from '../../../assets/images/footer-logo.png';
// import MobileLogo from '../../../assets/svgs/footer-logo-mobile.svg';
import CertifiedLogo from '../../../assets/svgs/climate-certificate.svg';

const Footer = props => {
  // const [innerWidth, setInnerWidth] = React.useState(0);
  // const handleResize = () => {
  //   setInnerWidth(window.innerWidth);
  // };
  // React.useEffect(() => {
  //   setInnerWidth(window.innerWidth);
  //   window.addEventListener('resize', handleResize);
  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, []);

  const currentYear = new Date().getFullYear()

  return (
    <footer className='footer'>
      <div className='row'>
        <div className='footerForm'>
          <h2>New York & Los Angeles</h2>
          <NewsletterForm {...props.siteSettings} />
        </div>
        <div className='col-space' />
        <div className='footerNavColumn'>
          <h2>Contact Us</h2>
          <ul>
            <li>
              <Link to={`/contact/client-inquiries`}>New Client Inquiries</Link>
            </li>
            <li>
              <Link to={`/contact/careers`}>Careers</Link>
            </li>
            {props?.siteSettings?.mentorshipFormToggle && (
              <li>
                <Link to={`/contact/mentorship-program`}>Mentorship Program</Link>
              </li>
            )}
          </ul>
        </div>
        <div className='footerNavColumn'>
          <h2>Follow US</h2>
          <ul>
            <li>
              <Link to='https://www.instagram.com/roandcostudio/'>Instagram</Link>
            </li>
            <li>
              <Link to='http://romance-journal.com'>Romance Journal</Link>
            </li>
          </ul>
        </div>
      </div>
      <div className='footerEnd'>
        <div className='climate-certificate'>
          <img src={CertifiedLogo} alt='Climate Neutral' loading='lazy' />
        </div>
        <div className='space' />
        <div className='footerAccessible'>
          <Link className='mt-5' to='/privacy-policy'>
            Privacy Policy
          </Link>
          <div className='footer-logo'>
            <span>© Roanne Adams Design LLC {currentYear}</span>
            <Link to='/' className='logo'>
              <img src={Logo} alt='Ro agency | Ro branding ' loading='lazy' />
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
