import { graphql, StaticQuery } from 'gatsby';
import React, { useState } from 'react';
import Layout from '../components/layout';
import '../styles/global.scss';
import { CookiesProvider } from 'react-cookie';

const query = graphql`
  query SiteTitleQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      ...SanitySiteSettingsFragment
      mentorshipFormToggle
    }
  }
`;

function LayoutContainer(props) {
  return (
    <StaticQuery
      query={query}
      render={data => {
        if (!data.site) {
          throw new Error(
            'Missing "Site settings". Open the studio at http://localhost:3333 and add "Site settings" data'
          );
        }
        return (
          <CookiesProvider>
            <Layout
              {...props}
              className={props.className}
              siteSettings={data.site}
              siteTitle={data.site.title}
            />
          </CookiesProvider>
        );
      }}
    />
  );
}

export default LayoutContainer;
