import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { cn } from "../lib/helpers";

import styles from "./nav.module.scss";
import NavEntry from "./nav-entry";

function Nav(props) {
  const links = useStaticQuery(graphql`
    {
      sanitySiteSettings {
        menu {
          menuEntries {
            _key
            assets {
              ...SanityAssetImageFragment
              ...SanityAssetVimeoFragment
            }
            title
            url
          }
        }
      }
    }
  `);

  const entries = links.sanitySiteSettings.menu.menuEntries;
  const menuHeight = entries.length * 70;

  return (
    <nav
      aria-label="Main menu"
      aria-expanded={props.showNav ? "true" : "false"}
      className={cn(styles.nav, props.showNav && styles.showNav)}
    >
      <ul>
        {entries.map((node, key) => (
          <NavEntry
            key={key}
            menuHeight={menuHeight}
            showNav={props.showNav}
            onHideNav={props.onHideNav}
            onShowNav={props.onShowNav}
            {...node}
          />
        ))}
      </ul>
    </nav>
  );
}

export default Nav;
