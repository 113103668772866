import React from "react";
import style from "./index.module.css";

const strokeStyle = { vectorEffect: "non-scaling-stroke" };

const CloseIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={style.close}
    title="Close"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.2858 0L9.99949 9.28633L0.715188 0L0 0.714177L9.28633 10.0005L0 19.2858L0.715188 20L9.99949 10.7147L19.2858 20L20 19.2858L10.7147 10.0005L20 0.714177L19.2858 0Z"
      fill="currentColor"
    />
  </svg>
);

export default CloseIcon;
