import React from "react";

const LogoIcon = () => (
  <svg
    viewBox="0 0 1086.23 180.46"
    preserveAspectRatio="xMidYMid meet"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    title="RoAndCo Logo"
  >
    <path
      d="M236 0c-48.51 0-83 40-83 87.71 0 38.8 28.33 71 70.24 71.4l1.17-3.1c-22.12-3.11-44.63-30.66-44.63-71.8 0-41.52 15.52-79.55 54.33-79.55s56.27 39.58 56.27 79.55c0 55.89-31 91.59-84.21 91.59-37.26 0-72.57-16.69-114.48-78.39L69.08 90h1.16c42.3 0 66-19 66-45 0-24.45-20.18-42.69-61.71-42.69H0v3.9c17.08 0 18.63 12.42 18.63 35.7v84.6c0 23.68-1.55 35.71-18.63 35.71v3.88h60.93v-3.88c-17.08 0-19-12-19-35.71V90h4.27c8.54 0 14.36 3.88 21.73 14.75 38.81 58.21 84.22 75.68 135.83 75.68 64 0 113.32-40 113.32-99.35C317.06 34.93 284.46 0 236 0zM42 85.77V41.91C42 13.97 43.55 6.6 61 6.6h8.54c26.77 0 40 17.46 40 38.42 0 26-12.81 40.75-38.81 40.75zM1005.12 0c-48.12 0-83 39.58-83 87.71 0 45 33 81.1 81.11 81.1 48.51 0 83.05-39.19 83.05-87.7-.05-46.18-32.65-81.11-81.16-81.11zm.39 164.16c-36.48 0-56.66-36.48-56.66-79.95 0-40.75 16.3-79.55 54.33-79.55 36.87 0 56.27 38.8 56.27 79.55s-16.69 79.95-53.94 79.95zM761 87.32c0 49.28 36.87 81.49 90.81 81.49 19.4 0 40.36-5 55.49-13.58v-30.5h-3.88c-5.31 27.93-27.42 39.43-50.06 39.43-44.63 0-65.58-36.09-65.58-83 0-41.14 21.34-76.45 64-76.45 28 0 47.2 15 51.61 39.31h3.88V13.58C892.19 4.66 871.23 0 851.83 0 801.38 0 761 34.54 761 87.32zM645.37 2.33h-56.66v3.88c17.08 0 18.63 12.42 18.63 35.7v83L499.46 2.33h-39.2v3.88c17.07 0 18.63 12.42 18.63 35.7v83.83L361.3 2.33h-37.64v3.88c17.07 0 18.62 12.42 18.62 35.7v84.6c0 23.68-1.55 35.71-18.62 35.71v3.88h45v-3.88c-16.3 0-19-11.26-19-32.21v-28.58h73.45l52.28 55.74c-2.71 3.11-7 5.05-13.58 5.05v3.88h63.26v-3.88c-8.54 0-12-.78-17.47-6.21l-21.34-22.51V23.67l117.2 133.5c-2.72 3.49-7 5.05-13.19 5.05v3.88h62.48c53.94 0 92.75-29.5 92.75-83.83 0-53.55-40.75-79.94-100.13-79.94zM349.66 95.08v-71.8l67.52 71.8zm305.41 66.36h-4.26c-10.09 0-13.2-1.94-20.57-10.48l-14.75-16.76V38.81c0-24.84 1.94-30.66 18.24-30.66h14.75c44.24 0 70.24 29.88 70.24 79.94 0 43.47-26.39 73.35-63.65 73.35z"
      fill="currentColor"
    />
  </svg>
);

export default LogoIcon;
