import React from 'react';
import Header from './header';
import Footer from './footer';
import styles from './layout.module.scss';

const Layout = ({ children, visible, siteTitle, siteSettings, className }) => (
    <div className={[styles.root, styles[className]].join(' ')}>
        <Header siteTitle={siteTitle} visible={visible} />
        {children}
        <Footer siteSettings={siteSettings} />
    </div>
);

export default Layout;