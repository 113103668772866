import React from "react";
import style from "./index.module.css";

const strokeStyle = { vectorEffect: "non-scaling-stroke" };

const HamburgerIcon = () => (
  <svg
    viewBox="0 0 27 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid"
    width="1em"
    height="1em"
    className={style.hamburger}
    title="Menu"
  >
    <g fill="none">
      <path d="M0 6.5h27m-27 6h27M0 .5h27" stroke="currentColor" style={strokeStyle} />
    </g>
  </svg>
);

export default HamburgerIcon;
