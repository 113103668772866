import { navigate } from 'gatsby-link';
import React, { Component } from 'react';
import Icon from '../../icon';
import theme from '../../../styles/theme.module.scss';
import arrowRight from '../../../images/arrow_right.svg';

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      feedbackMsg: null,
      formSuccess: this.props.formSuccess,
      formError: this.props.formError,
    };
    this.domRef = React.createRef();
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch('https://hooks.zapier.com/hooks/catch/615425/b4yxudw', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        emailaddress: this.state.emailaddress,
      }),
    })
      .then(() => {
        this.setState({
          feedbackMsg: this.state.formSuccess,
        });
        this.domRef.current.reset();
      })
      .catch(error => {
        this.setState({
          feedbackMsg: this.state.formError,
        });
      });
  };

  render() {
    return (
      <form
        ref={this.domRef}
        className={`${theme.gds3} ${theme.gde10} ${theme.gmmain} ${theme.fdcont} ${theme.fmcont}`}
        name='newsletter'
        method='post'
        data-netlify='true'
        data-netlify-honeypot='bot-field'
        onSubmit={this.handleSubmit}>
        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
        <input type='hidden' name='form-name' value='newsletter' />
        <div hidden>
          <label>
            Don’t fill this out: <input name='bot-field' onChange={this.handleChange} />
          </label>
        </div>
        <input
          className={theme.h5_founders}
          name='emailaddress'
          id='emailaddress'
          type='email'
          placeholder='Sign up to our newsletter'
          required
          onChange={this.handleChange}
          aria-label='Email Address'
        />

        <button type='submit'>
          <img src={arrowRight} alt='Submit Form' aria-label='Submit' />
        </button>
        <p className={`${theme.mT10}`}>{this.state.feedbackMsg}</p>
      </form>
    );
  }
}
