import React from "react";
import Img from "gatsby-image";
import { getFluidGatsbyImage } from "gatsby-source-sanity";
import clientConfig from "../../client-config";

function Image(props) {
  if (!props.id) {
    return null;
  }

  const fluidProps = getFluidGatsbyImage(
    props.id,
    { maxWidth: props.maxWidth, maxHeight: props.maxHeight },
    clientConfig.sanity
  );

  return (
    <Img
      fluid={fluidProps}
      alt={props.alt}
      style={props.style}
      imgStyle={props.imgStyle}
      className={props.className}
    />
  );
}

export default Image;
