import React from "react";
import style from "./index.module.css";

const ArrowRightIcon = (props) => (
  <svg
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={props.className}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.91984 0L8.19961 0.714502L17.0502 9.49469H0V10.5053H17.0502L8.19961 19.2855L8.91984 20L19 10L8.91984 0Z"
      fill="currentColor"
    />
  </svg>
);

export default ArrowRightIcon;
