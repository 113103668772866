import React from "react";

const LogoIcon = () => (
  <svg
    viewBox="0 0 622.02 104.27"
    preserveAspectRatio="xMidYMid meet"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    title="RoAndCo Logo"
  >
    <path
      d="M370.29 1.23H337.9v3c9.76 0 10.61 6.82 10.61 20.17v47.38l-62-70.55h-22.38v3c9.85 0 10.61 6.82 10.61 20.17v47.86l-67.43-71h-21.5v3c9.66 0 10.61 6.82 10.61 20.17v48.49c0 13.54-.95 20.08-10.61 20.08v3.12h25.76V93c-9.38 0-10.89-6.06-10.89-18V58.42h41.78l30.1 32.12a10.93 10.93 0 01-7.48 2.46v3.12h36.27V93c-4.93 0-7.1-.28-10.13-3.41L279 76.81v-62.7l67 76.43c-1.42 1.7-3.88 2.46-7.29 2.46v3.12h35.89c30.88 0 53.23-17.14 53.23-48.58-.05-31.06-23.54-46.31-57.54-46.31zM200.68 54.34V13.83l38 40.51zm175.2 38.28h-2.37c-5.87 0-7.67-1.14-11.83-5.87l-8.43-9.59V22.54c0-14.11 1-17.14 10.41-17.14h8.53c25.28 0 40.25 16.76 40.25 45.46 0 24.71-14.97 41.76-36.56 41.76zM135.43 0c-27.75 0-47.64 23-47.64 50.67 0 22.54 16.29 41.19 40.16 41.38l.85-2.55c-12.8-1.71-25.67-17.34-25.67-40.82 0-23.77 8.81-45.37 31.16-45.37s32.29 22.54 32.29 45.37c0 32.29-18.37 52.18-48.39 52.18-21.4 0-41.76-9.57-65.72-45L42 52.37h-1.66c24.15 0 37.79-11 37.79-26.23 0-14.3-11.65-24.91-35.32-24.91H0v3c9.85 0 10.7 6.82 10.7 20.17v48.52C10.7 86.46 9.85 93 0 93v3.12h35V93c-9.76 0-10.9-6.54-10.9-20.08V52.37h2.47c4.92 0 8.24 2.18 12.4 8.43 22.45 33.43 48.49 43.47 77.94 43.47 36.66 0 65.07-23 65.07-57.39C181.93 20.08 163.18 0 135.43 0zM24.05 49.25V24.43c0-16 .86-20 10.9-20h4.92c15.44 0 22.92 10 22.92 21.69 0 15.06-7.67 23.11-22.26 23.11zM436.68 50.38c0 28.32 20.84 47.26 52.18 47.26 11.27 0 23.21-2.94 31.82-8V71.91h-2.74c-2.18 16-14.93 22.32-28.13 22.32C464 94.23 452 73.59 452 46.88c0-23.3 12.13-43.57 36.84-43.57 14.59 0 27.66 7.58 29.08 22.36h2.74V7.86C512 2.56 499.94 0 488.86 0 460 0 436.68 19.89 436.68 50.38zM575.52 0C548 0 527.79 22.82 527.79 50.67c0 26.14 19 47 46.59 47C602.13 97.64 622 75 622 46.88 622 20.08 603.27 0 575.52 0zm.19 94.23c-21 0-32.58-20.64-32.58-45.55 0-23.3 9.37-45.37 31.25-45.37 21.21 0 32.29 22.07 32.29 45.37s-9.56 45.55-30.96 45.55z"
      fill="currentColor"
    />
  </svg>
);

export default LogoIcon;
