import Link from "./link";
import React, { useState } from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import Asset from "./assetMenu";
import theme from "../styles/theme.module.scss";
import styles from "./nav.module.scss";

function NavEntry(props) {
  const [inHover, setHover] = useState(false);
  const breakpoints = useBreakpoint();
  return (
    <li key={props.index} className={theme.h2_founders}>
      <Link
        className={theme.text_link}
        to={props.url}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={props.onHideNav}
        onFocus={() => setHover(true)}
        onBlur={() => setHover(false)}
      >
        {props.title}
      </Link>
      {inHover && !breakpoints.md && (
        <>
          <Asset
            asset={props.assets[0]}
            height={props.maxHeight}
            width={props.maxWidth}
            menuHeight={props.menuHeight}
            className={styles.entryAsset}
          />
        </>
      )}
    </li>
  );
}

export default NavEntry;
