import React from "react";
import HamburgerIcon from "./hamburger";
import CloseIcon from "./close";
import LogoIcon from "./logo";
import LogoMobileIcon from "./logomobile";
import ArrowLeftIcon from "./arrow-left";
import ArrowRightIcon from "./arrow-right";

function Icon(props) {
  switch (props.symbol) {
    case "hamburger":
      return <HamburgerIcon className={props.className} />;
    case "close":
      return <CloseIcon className={props.className} />;
    case "logo":
      return <LogoIcon className={props.className} />;
    case "logomobile":
      return <LogoMobileIcon className={props.className} />;
    case "arrowLeft":
      return <ArrowLeftIcon className={props.className} />;
    case "arrowRight":
      return <ArrowRightIcon className={props.className} />;
    default:
      return <span>Unknown icon: {props.symbol}</span>;
  }
}

export default Icon;
