import React from "react";
import style from "./index.module.css";

const ArrowLeftIcon = () => (
  <svg viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.0802 0L10.8004 0.714502L1.94982 9.49469H19V10.5053H1.94982L10.8004 19.2855L10.0802 20L0 10L10.0802 0Z"
      fill="currentColor"
    />
  </svg>
);

export default ArrowLeftIcon;
