import React from "react";
import Image from "./image";
import AssetImage from "./assetImage";

import ReactPlayer from "react-player/lazy";
import styles from "./nav.module.scss";

function Asset(props) {
  if (props.asset) {
    const asset = props.asset;
    if (asset._type == "assetImage") {
      return (
        <AssetImage
          asset={asset.imageDesktop.asset}
          height={props.maxHeight}
          width={props.maxWidth}
          className={props.className}
          aspectLock={false}
        />
      );
    } else if (asset._type == "assetVimeo") {
      const link = asset.videoDesktop.link;
      const elHeight = asset.videoDesktop.height;
      const elwidth = asset.videoDesktop.width;
      const newWidth = (props.menuHeight / elHeight) * elwidth;

      return (
        <div className={props.className}>
          <ReactPlayer
            width={newWidth}
            height={props.menuHeight}
            url={link}
            playing="true"
            controls="false"
            loop="true"
            muted="true"
          />
        </div>
      );
    }
  } else {
    return null;
  }
}

export default Asset;
