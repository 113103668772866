import React, { useEffect, useState } from 'react';
import { AspectRatio } from "react-aspect-ratio";
import SanityImage from "gatsby-plugin-sanity-image";

function AssetImage({ className, ...props }) {
  if (!props.asset) return null;
  const [aspectRatio, setAspectRatio] = useState(0)

  useEffect(() => {
    if (props.width && props.height) {
      setAspectRatio(props.width / props.height)
    } else {
      setAspectRatio(props.asset.fluid.aspectRatio)
    }
  }, [])

  if (props.aspectLock == false) {
    return <SanityImage {...props} width={props.width} height={props.height} alt={props.alt} className={className} />;
  }

  return (
    <AspectRatio ratio={aspectRatio} className={className}>
      <SanityImage {...props} width={props.width} height={props.height} alt={props.alt} />
    </AspectRatio>
  );
}

export default AssetImage;
